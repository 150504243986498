<template>
  <section class="w-full bg-white">
    <div class="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-4 mx-3 py-2">
      <div class="flex flex-col">
        <label for="icon">Fecha inicial</label>
        <input class="p-button-secondary w-full border p-1.5 rounded-md flex-items-center"
               v-model="filters.fechaInicial" type="date">
      </div>
      <div class="flex flex-col">
        <label for="icon">Fecha final</label>
        <input class="p-button-secondary w-full border p-1.5 rounded-md flex-items-center"
               v-model="filters.fechaFinal" type="date">
      </div>
      <div class="flex flex-col">
        <label for="nombre">Proveedor</label>
        <div>
          <Dropdown
            v-model="filters.proveedor"
            :options="proveedores"
            class="rounded-md border-gray-300 w-full p-inputtext-xs"
            @click="$h.xxsInput"
            optionLabel="CardName"
            panelClass="text-xxs"
            :showClear="true"
            optionValue="CardCode"
            :filter="true"
            placeholder="Seleccione un proveedor"/>
        </div>
      </div>
      <div class="flex flex-col">
        <label>Num. Factura</label>
        <InputText class="w-full mb-1 p-inputtext-xs" v-model="filters.numFactura" type="text"/>
      </div>
    </div>
    <div class="grid grid-cols-1 mx-3">
      <div class="flex flex-col">
        <label>Número entrada</label>
        <Chips
          id="numPedidos"
          v-model="filters.numEntrada"
          :allowDuplicate="false"
          separator=","
          inputClass="p-inputtext-xs"
          class="text-xs p-inputtext-xs w-full"
          placeholder="Pedidos separados por ,"
        />
      </div>
    </div>
    <div class="flex gap-4 pr-4 mb-4 lg:mb-0 justify-end pb-4 pt-2">
      <div class="pb-2 flex">
        <Button class="border-0" label="Buscar" @click="searchFilters"/>
      </div>
      <div class="pb-2 flex">
        <Button class="border-0 p-button-outlined p-button-secondary" label="Limpiar" @click="clearFilters"/>
      </div>
    </div>
  </section>
  <div class="pt-6">
    <DataTable
      :value="entradasMercancias"
      class="p-datatable-xs text-xs"
      dataKey=DocNum
      filterDisplay="menu"
      responsiveLayout="scroll"
      v-model:selection="selectedCustomers"
    >
      <template #empty>
        {{  `No existen ${ actuallyRouter === 'pharmasan.compras.logistica.recepcion-pedidos.entradas-de-mercancia-devoluciones' ? 'devoluciones' : 'entradas' } con los criterios de búsqueda` }}
      </template>
      <Column field="DocNum" bodyStyle="text-align: center;" headerStyle="text-align: center;justify-content: center;"
              header="N. Entrada" style="min-width: 5rem">
        <template #body="{data}">
          <Button :label="`${data.DocNum}`"
                  class="p-button-link text-black"
                  style="font-size: 0.75rem"
                  @click="openModalDetails(data.DocNum)"
          />
        </template>
      </Column>
      <Column field="DocDate" bodyStyle="text-align: center" headerStyle="text-align: center;justify-content: center;"
              header="Fecha" style="min-width: 8rem"/>
      <Column field="NumAtCard" bodyStyle="text-align: center" headerStyle="text-align: center;justify-content: center;"
              header="N. Factura" style="min-width: 10rem" />
      <Column field="" bodyStyle="text-align: center" headerStyle="text-align: center;justify-content: center;"
              header="Devoluciones" style="min-width: 5rem">
        <template #body="{data}">
          <div>
            <Button
              v-tooltip.top="'Visualizar devoluciones activas'"
              class="p-0 p-button-rounded p-button-xs p-button-primary p-button-text"
              aria-haspopup="true" aria-controls="overlay_panel_devoluciones"
              v-if="data.devoluciones.length"
              @click="toggleDevoluciones($event,data)"
            >
              <eyeIcon class="w-4"/>
            </Button>
            <OverlayPanel ref="opDevoluciones" appendTo="body" :showCloseIcon="true" id="overlay_panel_devoluciones"
                          style="width: 400px"
                          :breakpoints="{'960px': '75vw'}">
              <DataTable
                :value="devolucionesList"
                class="p-datatable-sm text-xxs"
                showGridlines
                dataKey="DocEntry"
                responsiveLayout="scroll"
              >
                <Column field="DocNum" header="Num. Devolución" headerStyle="justify-content: center;"
                        style="min-width:1rem"
                        bodyStyle="text-align: center"/>
                <Column field="" header="Acciones" headerStyle="justify-content: center;"
                        style="min-width:1rem"
                        bodyStyle="text-align: center">
                  <template #body="slotProps">
                    <Button
                      v-tooltip.top="'Visualizar devolución'"
                      class="p-0 p-button-rounded p-button-xs p-button-primary  p-button-text"
                      aria-haspopup="true" aria-controls="overlay_panel_devoluciones"
                      @click="verDevolucion($event,{DocEntry: slotProps.data.DocEntry, idRecepcion: data.NroCabeceraRecepcion})"
                    >
                      <eyeIcon class="w-4"/>
                    </Button>
                  </template>
                </Column>
              </DataTable>
            </OverlayPanel>
          </div>
        </template>
      </Column>
      <Column field="CardName" bodyStyle="text-align: center" headerStyle="text-align: center;justify-content: center;"
              header="Proveedor" style="min-width: 15rem"/>
      <Column field="U_PHR_UserWs" bodyStyle="text-align: center"
              headerStyle="text-align: center;justify-content: center;" header="Usuario"
              style="min-width: 10rem"/>
      <Column field="" bodyStyle="text-align: center" headerStyle="text-align: center;justify-content: center;"
              header="Acciones" style="min-width: 8rem">
        <template #body="{data}">
          <div class="flex justify-center gap-2">
            <Button icon="pi pi-replay" v-tooltip.top="'Generar Documento Devolución'"
                    v-if="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-entradas.crear-devolucion') && actuallyRouter === 'pharmasan.compras.logistica.recepcion-pedidos.entradas-de-mercancia-devoluciones' && data.estadoNombre === 'ABIERTO' && data.devoluciones.length === 0"
                    class="p-button-rounded p-button-xs p-button-danger" @click="openDevolucion(data)"/>
            <Button icon="pi pi-print" v-tooltip.top="'Imprimir Entrada'"
                    class="p-button-rounded p-button-xs p-button-secondary" @click="imprimirPdf(data.DocNum)"/>
          </div>
        </template>
      </Column>
    </DataTable>
    <Paginator v-model:first="offset"
               :rows="limit"
               :totalRecords="totalRecords"
               :rowsPerPageOptions="[10,20,30,50]"
               :pageLinkSize="pageLinkSize"
               @page="onPage($event)"
    />
  </div>
  <div>
    <modalDetails ref="openModalEntrada" @refresh="getPaginate"/>
  </div>
  <div>
    <modalDevolucion ref="modalDevolucionRef" @refresh="getPaginate"/>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed, defineAsyncComponent, watch } from 'vue'
import RecepcionPedidosService from '../../../services/rcp-pedidos.service'
import modalDetails from './modalDetails.vue'
import dayjs from 'dayjs'
import recepcionPedidosStore from '../../../store/recepcionPedidos'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
// import Swal from 'sweetalert2'

export default {
  name: 'listarEntradasMercancia',
  components: {
    modalDetails: defineAsyncComponent(() =>
      import('./modalEntradaMercancia.vue')
    ),
    modalDevolucion: defineAsyncComponent(() => import('./modalDetalleDevolucion.vue'))
  },
  setup () {
    const _RecepcionPedidosService = new RecepcionPedidosService()
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const user = computed(() => store.getters['auth/user'])
    const actuallyRouter = ref('')
    const recepcionPedidos = computed(() => recepcionPedidosStore.getters._permiso)
    const openModalEntrada = ref()
    const selectedCustomers = ref()
    const opDevoluciones = ref()
    const modalDevolucionRef = ref()
    const offset = ref(0)
    const page = ref(0)
    const limit = ref(10)
    const totalRecords = ref(0)
    const pageLinkSize = ref(5)
    const entradasMercancias = ref([])
    const proveedores = ref([])
    const devolucionesList = ref([])
    const bodegas = ref([])
    const filters = reactive({
      bodegas: [],
      proveedor: null,
      numEntrada: [],
      fechaFinal: null,
      fechaInicial: null,
      numFactura: null
    })
    const params = computed(() => {
      return {
        offset: offset.value,
        page: page.value,
        limit: limit.value,
        fechaInicial: filters.fechaInicial,
        fechaFinal: filters.fechaFinal,
        bodegas: [...new Set(filters.bodegas.map((d) => d.WhsCode))],
        numEntrada: filters.numEntrada,
        proveedor: filters.proveedor,
        numFactura: filters.numFactura,
        parametro: actuallyRouter.value === 'pharmasan.compras.logistica.recepcion-pedidos.entradas-de-mercancia' ? 0 : 1
      }
    })
    const getProveedores = () => {
      _RecepcionPedidosService.getProveedores().then(({ data }) => {
        proveedores.value = data
      })
    }
    const openModalDetails = (DocNum) => {
      _RecepcionPedidosService.getEntradaDetails(DocNum).then(({ data }) => {
        openModalEntrada.value.openModalEntrada(data)
      })
    }
    const openDevolucion = ({ DocEntry }) => {
      router.push({
        name: 'pharmasan.compras.logistica.recepcion-pedidos.crear-devolucion',
        params: { DocEntry: DocEntry }
      })
    }
    const imprimirPdf = async (DocNum) => {
      // const inputOptions = new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve({
      //       1: 'Distribución asignada',
      //       0: 'Entrada de mercancía'
      //     })
      //   }, 1000)
      // })
      // await Swal.fire({
      //   title: 'Selecciona una opción',
      //   input: 'radio',
      //   width: 600,
      //   icon: 'question',
      //   inputOptions: inputOptions,
      //   inputValidator: (value) => {
      //     if (!value) {
      //       return '¡Debes seleccionar una opción primero!'
      //     }
      //   }
      // })
      _RecepcionPedidosService.imprimirPdfEntrada(DocNum)
    }
    const onPage = ({ first, page, pageCount, rows }) => {
      limit.value = rows
      getPaginate()
    }
    const searchFilters = () => {
      getPaginate()
    }
    const clearFilters = () => {
      filters.bodegas = []
      filters.proveedor = null
      filters.numEntrada = []
      filters.numFactura = null
      getPaginate()
    }
    const getBodegas = () => {
      _RecepcionPedidosService.getBodegas().then(({ data }) => {
        bodegas.value = data
      })
    }
    const getPaginate = async () => {
      await _RecepcionPedidosService.getEntradasMercancias(params.value).then(({ data }) => {
        entradasMercancias.value = data.data
        totalRecords.value = data.rows[0].countRows
      })
    }
    const toggleDevoluciones = (event, data) => {
      devolucionesList.value = data.devoluciones
      opDevoluciones.value.toggle(event)
    }
    const verDevolucion = async (event, { DocEntry, idRecepcion }) => {
      await _RecepcionPedidosService.getDetalleDevolucion(DocEntry).then(({ data }) => {
        data.idRecepcion = idRecepcion
        modalDevolucionRef.value.openModalDevolucion(data)
      })
    }
    watch(() => route.name, async (val) => {
      if (['pharmasan.compras.logistica.recepcion-pedidos.entradas-de-mercancia', 'pharmasan.compras.logistica.recepcion-pedidos.entradas-de-mercancia-devoluciones'].includes(val)) {
        await validaciones()
      }
    })
    const verificarPermiso = (permiso) => {
      const user = store.getters['auth/user']
      if (!user.admin) {
        const permissions = store.getters['auth/getPermissions']
        if (permissions.some(a => a === permiso)) return true
        return false
      }
      return true
    }
    const validaciones = async () => {
      actuallyRouter.value = route.name
      if (!verificarPermiso('pharmasan.compras.logistica.recepcion-pedidos.listado-entradas')) {
        await router.push({ name: 'pharmasan.compras.logistica.recepcion-pedidos.listar-pedidos' })
        return
      }
      const date = new Date()
      const now = new Date()
      const lastMonth = date.setMonth(date.getMonth() - 1)
      filters.fechaInicial = dayjs(lastMonth).format('YYYY-MM-DD')
      filters.fechaFinal = dayjs(now).format('YYYY-MM-DD')
      getPaginate()
      getBodegas()
      getProveedores()
    }

    onMounted(async () => {
      await validaciones()
    })
    return {
      entradasMercancias,
      selectedCustomers,
      bodegas,
      filters,
      getPaginate,
      params,
      onPage,
      page,
      offset,
      limit,
      pageLinkSize,
      totalRecords,
      searchFilters,
      clearFilters,
      openModalDetails,
      modalDetails,
      openModalEntrada,
      getProveedores,
      getBodegas,
      proveedores,
      imprimirPdf,
      recepcionPedidos,
      route,
      openDevolucion,
      validaciones,
      verificarPermiso,
      user,
      actuallyRouter,
      toggleDevoluciones,
      devolucionesList,
      opDevoluciones,
      modalDevolucionRef,
      verDevolucion
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.p-multiselect-label ) {
  display: flex !important;
  flex-wrap: wrap !important;
}

.p-chips-token {
  padding: 0.1rem 0.5rem;
  margin-right: 0.4rem;
  margin-top: 2px;
  margin-bottom: 2px;
}

::v-deep(.p-chips-multiple-container) {
  padding: 0.1rem 0.6rem;
  max-height: 70px;
  overflow-y: auto;
}

::v-deep(.p-multiselect-label-container) {
  overflow-y: auto !important;
  max-height: 75px !important;
}

::v-deep(.country-item) {
  margin: 2px !important
}

::v-deep(.multiselect-custom) {
  .p-multiselect-label:not(.p-placeholder) {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .country-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .5rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
  }
}

.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #71717A;
  width: 100rem;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}

.floating-button {
  position: fixed !important;
  bottom: 35px;
  right: 40px;
  z-index: 1050;
}
</style>
